div#board {
  width: 95vw;
  height: 250px;
  background-color: whitesmoke;
  position: relative;
  display: inline-block;
  text-align: center;
  /* margin: 1rem; */
}

.tower:first-child {
  margin-left: 0;
}

.tower {
  width: 30%;
  height: 100%;
  position: relative;
  background-color: bisque;
  float: left;
  margin-left: 1rem;
  text-align:  center;
}

.bar {
  background-color: gray; 
  position: absolute;
  width: 10px;
  height: 150px; 
  top: 50px;
  display: inline-block;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.disk {
  position: absolute;
  bottom: 50px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background: silver;
}

.one { 
  width: 30px; 
  height: 20px;
  background-color: orange;
  margin: 0 auto;
}

.two {
  width: 50px; 
  height: 20px;
  background-color: green;
  margin: 0 auto;
}

.three {
  width: 70px;
  height: 20px;
  background-color: blue;
  margin: 0 auto;
}

.four {
  width: 90px;
  height: 20px;
  background-color: red;
  margin: 0 auto;
}
.five {
  width: 110px;
  height: 20px;
  background-color: purple;
  margin: 0 auto;
}

#inputMsg {
  color: red;
}

.selectTower {
  margin: 1rem;
}
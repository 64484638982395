// .App {
//   text-align: center;
// }


// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

$max-width: 100vw;
$boardColor: #ffdafb;

.App {
  text-align: center;

  &-logo {
    height: 10vmin;
    pointer-events: none;
  }
  
  &-header {
    background-color: #282c34;
    height: 20vh;
    width: $max-width;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 3vmin);
    color: white;

    .logo { 
      width: 20vw;
      flex-direction: row;
    }
    .title { 
      width: 80vw;
      text-align: left;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    max-width: $max-width;
    // max-height: calc(80vh - 2rem);
    height: calc(80vh - 2rem);
    // max-width: calc($max-width - 4rem);
    width: calc(#{$max-width} - 4rem);
    text-align: left;
    padding: 1rem 2rem;

    background: {
      color: $boardColor;
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
